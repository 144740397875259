import * as constants from "./storeConstants";
import { apiActions, apiUploadImageUrl, apiUploadAudioUrl } from "../services/constants";
import api, { apiLogin, apiComment, apiChampion, apiStatusTraining } from "../services/api";

export const authenticationLogin = ({ login, password }) => {
  return async dispatch => {
    dispatch({ type: constants.AUTH_START });
    try {
      const response = await apiLogin(login, password);
      dispatch({ type: constants.AUTH_SUCCESS });
      return response;
    } catch (error) {
      dispatch({ type: constants.AUTH_FAIL, payload: error.message ? error.message : error });
    }
  };
};

export const getGroupIQCounter = (listGroup = []) => {
  return async dispatch => {
    try {
      // eslint-disable-next-line array-callback-return
      Object.keys(listGroup).map(id => {
        const obj = listGroup[id];
        const { Activity: { Id = null } = {} } = obj;
        if (Id) {
          api({
            action: apiActions.getIQCounterByActivity,
            activityId: Id
          }).then(({ data }) => {
            if (data.hasOwnProperty("ICount") && data.hasOwnProperty("QCount")) {
              const { ICount, QCount, MCount } = data;
              dispatch({
                type: constants.GET_GROUP_IQ_COUNTER,
                payload: {
                  [id]: { ICount, QCount, MCount }
                }
              });
            }
          });
        }
      });
    } catch (error) {
      console.log("error in getGroupIQCounter", error);
    }
  };
};

export const getGroups = () => {
  return async dispatch => {
    try {
      const response = await api({
        action: apiActions.getCurrentGroups
      });
      const { data } = response;

      //if error from server
      if (data.login_error) {
        return data;
      }

      if (Object.keys(data).length > 0) {
        dispatch({ type: constants.GET_GROUP_LIST, payload: data });
        // load counters for each group
        dispatch(getGroupIQCounter(data));
      } else {
        dispatch({ type: constants.GET_GROUP_LIST_EMPTY, payload: data });
      }

      return data;
    } catch (error) {
      console.log("error", error);
      console.log("authenticationLogin error", error);
      //window.location.href = '/'; //redirect to login
    }
  };
};

export const getSingleGroup = Id => {
  return async dispatch => {
    try {
      const response = await api({
        Id,
        action: apiActions.getGroupById
      });

      const { data } = response;
      if (data.login_error === "login_error") {
        console.log("getSingleGroup data.login_error", response);
        return false;
      }
      dispatch({ type: constants.GET_GROUP_SINGLE, payload: data });
    } catch (error) {}
  };
};

export const saveParticipantVideo = (Id, videoFile = "") => {
  return async dispatch => {
    try {
      const response = await api({
        Id: Id,
        action: apiActions.saveParticipantVideo,
        fileToUpload: videoFile
      });

      const { data } = response;

      if (data.video_error === "video_error") {
        console.log("saveParticipantVideo data.video_error", response);
        return false;
      }

      dispatch({ type: constants.SET_VIDEO, payload: data });
      return data;
    } catch (error) {}
  };
};

export const getParticipantVideos = (Id, year = "", page = 1) => {
  return async dispatch => {
    try {
      const response = await api({
        Id: Id,
        action: apiActions.getParticipantVideos,
        year: year,
        page: page
      });

      const { data } = response;
      if (data.video_error === "video_error") {
        console.log("getVideoList data.video_error", response);
        return false;
      }

      dispatch({ type: constants.GET_VIDEO_LIST, payload: data });
    } catch (error) {}
  };
};

export const deleteParticipantVideo = (videoFile = "") => {
  return async dispatch => {
    try {
      const response = await api({
        access_token: "4457913f40397b0346f1f31964d52691",
        action: apiActions.deleteVideo,
        url: videoFile
      });

      const { data } = response;

      if (data.video_error === "video_error") {
        console.log("deleteParticipantVideo data.video_error", response);
        return false;
      }

      dispatch({ type: constants.DELETE_VIDEO, payload: data });
      return data;
    } catch (error) {}
  };
};

export const clearSingleGroup = () => dispatch => {
  dispatch({ type: constants.CLEAR_GROUP_SINGLE });
};

export const getActivityParticipants = props => {
  const { groupId, Id } = props;
  return async dispatch => {
    try {
      const response = await api({
        groupId,
        Id,
        action: apiActions.getActivityParticipantsByActivity
      });

      const { data } = response;
      if (typeof data === "string") {
        throw new Error("GET_ACTIVITY_PARTICIPANTS_LIST fail", data);
      }
      dispatch({ type: constants.GET_ACTIVITY_PARTICIPANTS_LIST, payload: data });
    } catch (error) {}
  };
};

export const clearActivityParticipants = () => dispatch => {
  dispatch({ type: constants.CLEAR_ACTIVITY_PARTICIPANTS_LIST });
};

export const insertActivityParticipant = ({ Id, SuAge, activityId, groupId }) => {
  return async (dispatch, getState) => {
    try {
      const response = await api({
        Id,
        SuAge,
        activityId,
        action: apiActions.insertActivityParticipantByContact
      });

      const { data } = response;
      if (data) {
        // if success need update participant list
        const {
          Activity: { Id }
        } = getState().groupSingle;
        if (Id) {
          dispatch(getActivityParticipants({ groupId, Id }));
        }
      }
    } catch (error) {}
  };
};

export const updateActivityParticipantPresent = (ActivityParticipantId, Id, status) => {
  return async dispatch => {
    try {
      const response = await api({
        Id: ActivityParticipantId,
        status,
        action: apiActions.updateActivityParticipantPresent
      });

      const { data } = response;
      if (data.success) {
        dispatch({ type: constants.UPDATE_ACTIVITY_PARTICIPANT_STATUS, payload: { Id, status } });
        return true;
      }
      return false;
    } catch (error) {}
  };
};

export const memberUpdateAvatarImage = (userId, fileToUpload) => {
  return async dispatch => {
    try {
      //action: 'upload_images'
      const formD = new FormData();
      formD.append("userId", userId);
      formD.append("fileToUpload", fileToUpload);
      formD.append("action", "updateContactPhoto");

      const response = await api(formD, {
        url: apiUploadImageUrl
      });

      if (response && response.data && response.data.success) {
        const filePath = `photos/${response.data.remoteFileName}`;
        dispatch({ type: constants.UPDATE_MEMBER_AVATAR_IMG, payload: { Id: userId, filePath } });
      } else if (response && response.data && response.data.success === false) {
        const { message = "" } = response.data;
        alert(message);
      }

      return response;
    } catch (error) {
      console.warn("memberUpdateAvatarImage", error);
    }
  };
};
export const memberUpdateRecord = (userId, fileToUpload) => {
  return async dispatch => {
    try {
      //action: 'upload_audio'
      const formD = new FormData();
      formD.append("userId", userId);
      formD.append("fileToUpload", fileToUpload, "audiofile.wav");
      const response = await api(formD, {
        url: apiUploadAudioUrl
      });

      if (response && response.data) {
        if (response.data.success) {
          const fileType = fileToUpload.type.split("/").pop();
          const filePath = `audio/${userId}.${fileType}`;
          dispatch({ type: constants.UPDATE_MEMBER_RECORD, payload: { Id: userId, filePath } });
        } else if (response.data.success === false) {
          const { message = "" } = response.data;
          alert(message);
        }
      }
      return response;
    } catch (error) {
      console.warn("memberUpdateAvatarImage", error);
    }
  };
};

export const addUserComment = (comment, userId) => {
  return async dispatch => {
    try {
      const response = await apiComment({
        comment,
        userId,
        action: apiActions.addComment
      });
      dispatch({ type: constants.ADD_MEMBER_COMMENT });
      return response;
    } catch (error) {
      dispatch({
        type: constants.ADD_MEMBER_COMMENT_FAIL,
        payload: error.message ? error.message : error
      });
    }
  };
};

export const addUserChampion = ({ groupId, userId, activityId }) => {
  return async dispatch => {
    try {
      const response = await apiChampion({
        groupId,
        userId,
        activityId,
        action: apiActions.addChampion
      });
      dispatch({ type: constants.ADD_CHAMPION });
      return response;
    } catch (error) {
      dispatch({
        type: constants.ADD_CHAMPION_FAIL,
        payload: error.message ? error.message : error
      });
    }
  };
};

export const deleteUserChampion = ({ groupId, userId, activityId }) => {
  return async dispatch => {
    try {
      const response = await apiChampion({
        groupId,
        userId,
        activityId,
        action: apiActions.deleteChampion
      });
      dispatch({ type: constants.DELETE_CHAMPION });
      return response;
    } catch (error) {
      dispatch({
        type: constants.DELETE_CHAMPION_FAIL,
        payload: error.message ? error.message : error
      });
    }
  };
};

export const addChampionId = championId => {
  return async dispatch => {
    dispatch({ type: constants.SET_CHAMPIONS, payload: championId });
  };
};

export const deleteChampionId = (champions, championId) => {
  return async dispatch => {
    const newChampions = champions.filter(id => id !== championId);
    dispatch({ type: constants.DELETE_CHAMPIONS, payload: newChampions });
  };
};

export const changeTrainingStatus = ({ status, activityId }) => {
  return async dispatch => {
    try {
      const response = await apiStatusTraining({
        status,
        activityId,
        action: apiActions.changeActivityStatus
      });
      dispatch({ type: constants.SET_TRAINING_STATUS, payload: status });
      return response;
    } catch (error) {
      dispatch({
        type: constants.SET_TRAINING_STATUS_ERROR,
        payload: error.message ? error.message : error
      });
    }
  };
};

export const setTrainingStatus = status => {
  return async dispatch => {
    dispatch({ type: constants.SET_TRAINING_STATUS, payload: status });
  };
};
