import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import {
  getSingleGroup,
  getActivityParticipants,
  clearActivityParticipants,
  clearSingleGroup
} from "../store/actions.js";

import MemberPopup from "../components/MemberPopup";
import AddMemberPopup from "../components/AddMemberPopup";
import GameFieldHeader from "../components/GameFieldHeader";
import GameMember from "../components/Game_field/GameMember";
import Loader from "../components/Loader";

var firstParticipantsIsGotten = false;
var activityIdForInterval = null;
var groupIdForInterval = null;

const Game_field = ({ history: routerHistory, match: routerMatch }) => {
  // member pop funcs
  const groupId = routerMatch.params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [memberPopId, setMemberPopId] = useState(null);
  const [addMemberPop, setAddMemberPop] = useState(false);
  const [isChampion, setIsChampion] = useState(false);
  const dispatch = useDispatch();
  const groupSingle = useSelector(state => state.groupSingle);
  const clubId = groupSingle["SuClub"] ? groupSingle["SuClub"].value : null;
  const activityId = groupSingle["Activity"] ? groupSingle["Activity"].Id : null;
  const activityParticipantsList = useSelector(state => state.activityParticipantsList);
  const championsIds = useSelector(state => state.championIds || []);
  const activeParticipant = useSelector(state => state.activityParticipantsList[memberPopId]) || {};
  const { Contact = {} } = activeParticipant;
  const { champion } = Contact;

  useEffect(() => {
    const interval = setInterval(() => {
      if (firstParticipantsIsGotten) {
        dispatch(
          getActivityParticipants({ groupId: groupIdForInterval, Id: activityIdForInterval })
        );
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getSingleGroup(groupId));
    return () => {
      dispatch(clearSingleGroup());
      dispatch(clearActivityParticipants());
      activityIdForInterval = null;
      groupIdForInterval = null;
      firstParticipantsIsGotten = false;
    };
  }, []);

  useEffect(() => {
    if (activityId && groupId) {
      dispatch(getActivityParticipants({ groupId, Id: activityId })).then(() => {
        setIsLoading(false);
        firstParticipantsIsGotten = true;
        activityIdForInterval = activityId;
        groupIdForInterval = groupId;
      });
    }
  }, [groupSingle]);

  const closeMemberPop = () => {
    setMemberPopId(null);
  };
  const openMemberPop = memberId => {
    setMemberPopId(memberId);
    setIsChampion(championsIds.some(playerId => playerId === memberId));
  };

  const pressOnBackButton = () => {
    routerHistory.push("/select-team");
  };

  const MemberPopupMemo = useMemo(
    () => (
      <MemberPopup
        closeMemberPop={closeMemberPop}
        memberPopOpen={!!memberPopId}
        memberPopId={memberPopId}
        groupId={groupId}
        isChampion={isChampion}
        setIsChampion={setIsChampion}
      />
    ),
    [memberPopId, champion, isChampion]
  );

  const AddMemberPopupMemo = useMemo(
    () => (
      <AddMemberPopup
        clubId={clubId}
        activityId={activityId}
        groupId={groupId}
        isShow={addMemberPop}
        onClose={() => setAddMemberPop(false)}
      />
    ),
    [addMemberPop, clubId, activityId]
  );

  const membersNoPresent = [];
  const membersPresent = [];

  for (let [key, value] of Object.entries(activityParticipantsList)) {
    if (value.SuIsPresent) {
      membersPresent.push({ ...value, Id: key });
    } else {
      membersNoPresent.push({ ...value, Id: key });
    }
  }
  return (
    <>
      <div className="game-field">
        <div className="game-field-bg">
          <img src={process.env.PUBLIC_URL + "/static/img/kiosk-bg-new.jpg"} alt="" />
        </div>
        <div className="container">
          <GameFieldHeader data={groupSingle} />

          <div className="game-field-body">
            {isLoading ? (
              <div className="game-field-loader">
                <Loader />
              </div>
            ) : (
              <>
                <div className="game-field-row">
                  <div className="game-field-label">
                    <div className="label-text">Roster</div>
                    <div className="label-info">{membersNoPresent.length}</div>
                  </div>

                  <div className="game-field-member add" onClick={() => setAddMemberPop(true)}>
                    <div className="member-img">
                      <div className="member-img-wrap"></div>
                    </div>
                    <div className="member-name">
                      <span>Зарегистрировать</span>
                    </div>
                  </div>
                </div>

                <div className="members-holder-row">
                  <div className="game-field-row space-bottom">
                    {membersNoPresent.map(item => (
                      <GameMember key={item.Id} data={item} openMemberPop={openMemberPop} />
                    ))}
                  </div>
                </div>

                <div className="game-field-row">
                  <div className="game-field-label">
                    <div className="label-text">Line up</div>
                    <div className="label-info">{membersPresent.length}</div>
                  </div>
                </div>

                <div className="members-holder-row">
                  <div className="game-field-row">
                    {membersPresent.map(item => (
                      <GameMember key={item.Id} data={item} openMemberPop={openMemberPop} />
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="btn-orange game-field-back" onClick={pressOnBackButton}>
          <span>ВЕРНУТЬСЯ В СПИСОК ГРУПП</span>
        </div>
      </div>

      {MemberPopupMemo}

      {AddMemberPopupMemo}
    </>
  );
};

export default withRouter(Game_field);
