import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Autosuggest from "react-autosuggest";
import StringMask from "string-mask";
import { useDispatch } from "react-redux";

import { autocompleteRequestApi } from "../services/api";
import { apiActions } from "../services/constants";
import { insertActivityParticipant } from "../store/actions";

var phoneFormatter = new StringMask("(000) 00-00*-***");

const normalizeMobilePhone = (phone = "") => {
  if (phone === "") return "";
  try {
    const onlyNumbWithSymb = phone.replace(/\D+/gi, "");
    var result = phoneFormatter.apply(onlyNumbWithSymb);
    return result;
  } catch (error) {
    return phone;
  }
};

let firstShow = true;

export default function AddMemberPopup({
  isShow,
  onClose,
  clubId,
  activityId,
  groupId,
}) {
  const [inputVal, setInputVal] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShow && clubId && firstShow) {
      console.log("first run ");
      loadSuggestions("");
      firstShow = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, clubId]);

  const onSuggestionsClearRequested = () => {
    console.log("onSuggestionsClearRequested");
    //setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (inputVal.trim() === value.trim()) return;
    loadSuggestions(value);
  };

  function loadSuggestions(fio) {
    setLoading(true);

    const obj = {
      action: apiActions.searchContactByFIO,
      Id: clubId,
      fio
    };
    autocompleteRequestApi(obj)
      .then(response => {
        if (response && response.data) {
          if (error) setError(false);
          setSuggestions(response.data);
        }
        setLoading(false);
      })
      .catch(e => {
        setError(true);
        setLoading(false);
      });
  }

  const onChangeInput = (event, { newValue }) => {
    setInputVal(newValue);
  };

  function getSuggestionValue(suggestion) {
    return "";
  }

  const pressAddMember = (Id, SuAge) => {
    setLoading(true);
    dispatch(insertActivityParticipant({ Id, SuAge, activityId: activityId, groupId }))
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  function renderSuggestion(suggestion) {
    // TODO
    const { Id, Name, Phone = "", SuAge } = suggestion;

    const clearName = Name.replace("not valid", "")
      .replace("Not valid", "")
      .replace("valid", "")
      .replace("transition", "");

    return (
      <div className="member">
        <div className="member-left">
          <div className="member-name">{clearName}</div>
          <div className="member-tel">{normalizeMobilePhone(Phone)}</div>
        </div>
        <div className="member-right">
          <button className="btn btn-orange" onClick={() => pressAddMember(Id, SuAge)}>
            <span>ЗАПИСАТЬСЯ</span>
          </button>
        </div>
      </div>
    );
  }

  const inputProps = {
    placeholder: "Введите вашe ФИО",
    value: inputVal,
    autoFocus: true,
    onChange: onChangeInput,
    disabled: !clubId
  };

  return (
    <>
      <Popup
        open={isShow}
        closeOnDocumentClick
        onClose={onClose}
        lockScroll={true}
      >
        <div className="add-member-popup">
          <div className="add-member-popup-input">
            <Autosuggest
              alwaysRenderSuggestions
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              focusInputOnSuggestionClick={false}
              inputProps={inputProps}
            />
            <div className="add-member-popup-statuses">
              {loading && !error ? (
                <div className="add-member-status">Загрузка...</div>
              ) : error && !loading ? (
                <div className="add-member-status">Ошибка загрузки данных</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
