export const apiActions = {
  login: "login",
  getCurrentGroups: "getCurrentGroups",
  getGroupById: "getGroupById",
  getActivityParticipantsByActivity: "getActivityParticipantsByActivity", //получение чуваков по ид тренировки где ид параметр это ид тренировки
  updateActivityParticipantPresent: "updateActivityParticipantPresent", // id это id чувака в сущности тренировки по которой ищем
  searchContactByFIO: "searchContactByFIO", //Id это Id клуба в котором ищем
  insertActivityParticipantByContact: "insertActivityParticipantByContact", //Запись нового чувака с поиска в тренировку
  getIQCounterByActivity: "getIQCounterByActivity", // Получение I Q количества для тренировки по ее ид,
  getImageFromCrm: "getImageFromCrm", //Получение картинки по урлу
  getParticipantVideos: "getParticipantVideos", //Получение списка видео юзера
  saveParticipantVideo: "saveParticipantVideo", //Загрузка нового видео
  deleteVideo: "deleteVideo", //Удаление видео
  addComment: "addComment",
  addChampion: "addChampion",
  deleteChampion: "deleteChampion",
  changeActivityStatus: "changeActivityStatus"
};

//old back api - remove this later
//https://kioskback.sheep.fish
//192.168.1.198:8000

// dev
/* export const apiUrl = "https://kioskback.sheep.fish";
export const apiUploadImageUrl = "https://kioskback.sheep.fish/";
export const apiUploadAudioUrl = "https://kioskback.sheep.fish/upload_audio.php"; */

// prod
export const apiUrl = 'https://kioskback.footbik.ua';
export const apiUploadImageUrl = 'https://kioskback.footbik.ua/';
export const apiUploadAudioUrl = 'https://kioskback.footbik.ua/upload_audio.php';

export const standartAudio = process.env.PUBLIC_URL + "/static/audio/footbik-sound-for-kiosk.mp3";

export const participantButtonText = {
  Icame: "Я пришел",
  Iout: "Выйти"
};

export const streamStatus = {
  idle: "idle",
  recording: "recording",
  stopped: "stopped"
};

export const deleteVideoKey = "footbik";

export const streamButtonStatusIcon = {
  refresh: process.env.PUBLIC_URL + "/static/img/refresh.svg",
  play: process.env.PUBLIC_URL + "/static/img/play.svg",
  pause: process.env.PUBLIC_URL + "/static/img/pause.svg"
};
