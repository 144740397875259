import React, { useRef, useEffect, useState } from "react";
import axios from 'axios'

import {apiActions} from '../services/constants'
import api from '../services/api'

const ImageWithCookie = ({ src }) => {
  const [imageSrc,setImageSrc] = useState(null)

  useEffect(() => {
    api({
      url: src,
      action: apiActions.getImageFromCrm
    },{responseType: 'blob'}).then(response => {
      let reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        setImageSrc(reader.result)
      }
    })
	}, [src]);

  if(imageSrc){
    return <img src={imageSrc} alt={"Загрузка..."} />;
  }
  return null
};

export default ImageWithCookie;
