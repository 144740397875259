import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUserComment, getActivityParticipants } from "../../store/actions.js";

import Popup from "reactjs-popup";
import { Formik, Form } from "formik";
import UserPopup, { errorTypes } from "../UserPopup.jsx";
import CommentPatternItem from "./CommentPatternItem.jsx";
import CommentItem from "./CommentItem.jsx";
import Loader from "../Loader.jsx";

const commentsPatterns = [
  "Хочет купить сейчас",
  "Дожимайте (думать пошел)",
  "Испугся цены",
  "Оплатит завтра",
  "Дал еще тренировку интро"
];

const CommentPopup = ({
  memberPopId,
  footballerId,
  groupId,
  setIsComment,
}) => {
  const dispatch = useDispatch();
  const [commentValue, setCommentValue] = useState("");
  const [isCommentDataLoading, setIsCommentDataLoading] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isCommentSubmitted, setIsCommentSubmitted] = useState(false);
  const [userErrors, setUserErrors] = useState({
    userError: "",
    commentError: ""
  });
  const [isUserDataCorrect, setIsUserDataCorrect] = useState(false);

  const { Contact = {} } = useSelector(state => state.activityParticipantsList[memberPopId]) || {};
  const { comments = []} = Contact;
  
  if (comments && comments !== null) {
    comments.sort((a, b) => {
      const dateA = parseCommentsDate(a.date);
      const dateB = parseCommentsDate(b.date);
  
      return dateA - dateB;
    });
  }

  const groupSingle = useSelector(state => state.groupSingle);
  const activityId = groupSingle["Activity"] ? groupSingle["Activity"].Id : null;

  const commentFormValidate = () => {
    let errors = {};
    if (commentValue.length < 1) {
      errors.commentError = errorTypes.required;
    }
    setUserErrors({ ...userErrors, ...errors });
    return errors;
  };

  const commentFormOnSubmit = async () => {
    setIsCommentDataLoading(true);
    setIsCommentLoading(true);

    setUserErrors({
      commentError: "",
      ...userErrors
    });

    try {
      commentFormValidate();
      if (commentValue.length > 0) {
        setIsCommentSubmitted(true);
        setCommentValue("");

        await dispatch(addUserComment(commentValue, footballerId));
        await dispatch(getActivityParticipants({ groupId, Id: activityId }));
    
        setIsCommentDataLoading(false);
        setIsCommentLoading(false);
      } else {
        setIsCommentDataLoading(false);
        setIsCommentLoading(false);
      }
    } catch (e) {
      setUserErrors({ ...userErrors, userError: errorTypes.default });
    }
  };

  const handleCommentChange = event => {
    setCommentValue(event.target.value);
    setUserErrors({
      ...userErrors,
      commentError: event.target.value.length === 0 ? errorTypes.required : ""
    });
  };

  const handleClickCancelButton = () => {
    if (setIsComment) {
      setIsComment(false);
    }
    /* if (setIsVideo) {
      setIsVideo(false);
    }; */
  };

  const handleClickSuccessCancelButton = () => {
    setIsCommentSubmitted(false);
  };

  const handleCommentsPatternClick = comment => {
    setCommentValue(comment);
    setUserErrors({
      ...userErrors,
      commentError: ""
    });
  };

  const handleCommentTextAreaClick = () => {
    if (window.innerWidth <= 768) {
      const commentsPatternswrap = document.getElementById("comments-patterns");

      if (commentsPatternswrap) {
        commentsPatternswrap.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }
  };
  
  function parseCommentsDate(dateString) {
    const [day, month, year, time] = dateString.match(/(\d{2})\.(\d{2})\.(\d{4}), (\d{2}:\d{2})/).slice(1);
    const [hours, minutes] = time.split(':');
    const validDateString = `${year}-${month}-${day}T${hours}:${minutes}:00`;

    return new Date(validDateString);
  };

  return (
    <>
      {isUserDataCorrect ? (
        <>
          <div className="popup-cancel-btn" onClick={handleClickCancelButton}>
            <img src={process.env.PUBLIC_URL + "/static/img/cross-icon.svg"} />
          </div>
          <div className="user-confirmation-wrap comment-wrap">
            <div className="comment-content-wrap">
              <div className="item comment-item">
                <div className="title title-comment">
                  <span>Оставить комментарий</span>
                </div>
              </div>

              <div id="comments-patterns" className="comments-patterns-wrap">
                {commentsPatterns.map((comment, index) => (
                  <CommentPatternItem
                    comment={comment}
                    key={index}
                    index={index}
                    handleCommentsPatternClick={handleCommentsPatternClick}
                  />
                ))}
              </div>

              <div className="form-wrap">
                <Formik
                  initialValues={{
                    comment: ""
                  }}
                  validateOnBlur={false}
                  onSubmit={commentFormOnSubmit}
                >
                  {() => (
                    <Form className="user-confirmation-form">
                      <div className="input-container">
                        <textarea
                          className="popup-custom-textarea"
                          placeholder="Почніть вводити"
                          maxLength="250"
                          value={commentValue}
                          onChange={handleCommentChange}
                          onClick={handleCommentTextAreaClick}
                        ></textarea>
                        <span className="popup-maxlength">250</span>
                        {userErrors.commentError ? (
                          <div className="popup-custom-input-error popup-custom-textarea-error">
                            {userErrors.commentError}
                          </div>
                        ) : null}
                      </div>
                      <div className="buttons-wrap">
                        <button
                          onClick={handleClickCancelButton}
                          className="btn btn-orange btn-form buttons-comment cancel-btn cancel-btn-comment"
                        >
                          <span>Отменить</span>
                        </button>
                        <button
                          type="submit"
                          disabled={isCommentDataLoading}
                          className="btn btn-orange btn-form buttons-comment comment-btn comment-btn-submit"
                        >
                          <span>Сохранить</span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="comments-block-wrap comment-content-wrap">
              <div className="comments-title">
                <span>Комментарии</span>
              </div>

              {isCommentLoading && (
                <div className="comments-loader">
                  <Loader />
                </div>
              )}

              {(!comments || comments.length < 1) && !isCommentLoading && (
                <div className="comments-empty">
                  <span>Комментариев не добавлено</span>
                </div>
              )}
              
              {comments && comments.length > 0 && !isCommentLoading && (
                <div className="comments-wrap">
                  {comments.map((comment, index) => (
                    <CommentItem commentCrm={comment} key={index} />
                  ))}
                </div>
              )}
            </div>

            <div className="success-popup">
              <Popup open={isCommentSubmitted} lockScroll={true}>
                <div className="success-content">
                   <div className="popup-cancel-btn success-cancel-btn" onClick={handleClickSuccessCancelButton}>
                    <img src={process.env.PUBLIC_URL + "/static/img/cross-icon.svg"} />
                  </div>
                  <div className="success-message">
                    <span>Ваш комментарий отправлен</span>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </>
      ) : (
        <div className="user-confirmation-wrap">
          <UserPopup
            handleCancel={setIsComment}
            isDataLoading={isCommentDataLoading}
            setIsDataLoading={setIsCommentDataLoading}
            setIsDataCorrect={setIsUserDataCorrect}
          />
        </div>
      )}
    </>
  );
};

export default CommentPopup;
