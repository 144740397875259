import React, { useRef, useEffect } from "react";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import localization from "moment/locale/ru";
import ImageWithCookie from "../ImageWithCookie";
import classNames from "classnames";

moment.locale("ru");

const SelectTeamItem = props => {
  const { pressOnSelectGroup, data, ICount, QCount, MCount } = props;

  const teamHolderRef = useRef(null);
  const teamImgRef = useRef(null);
  const teamNameRef = useRef(null);
  const teamButtonRef = useRef(null);

  let {
    AgeCategory = "",
    Id,
    SuTime = "",
    displayValue = "",
    SuMaxChildAmount = "",
    SuCurrentChildAmount = "",
    SuGroupImage = "",
    SuCoachArray = [],
    Activity: { SuTrainingNumber = "", ActivityType = "", Status = "" } = {}
  } = data;

  if (!SuCoachArray) SuCoachArray = [];

  useEffect(() => {
    setTimeout(() => {
      const teamHolderHeight = teamHolderRef.current.offsetHeight;
      const teamImgHeight = teamImgRef.current.offsetHeight;
      const teamNameHeight = teamNameRef.current.offsetHeight;
      const teamButtonHeight = teamButtonRef.current.offsetHeight;
      const sum = teamImgHeight + teamNameHeight + teamButtonHeight + 10;
      if (sum > teamHolderHeight) {
        const diff = Math.abs(teamHolderHeight - sum);
        teamImgRef.current.style.height = teamImgHeight - diff + "px";
      }
    }, 100);
  }, []);

  const correctSuTrainingNumber = typeof SuTrainingNumber === "string" ? SuTrainingNumber : "";
  const isAttestatia =
    correctSuTrainingNumber.toLowerCase().includes("a") ||
    correctSuTrainingNumber.toLowerCase().includes("а");

  const mainTeamCount = isAttestatia
    ? "Атестація"
    : `${SuMaxChildAmount} / ${SuCurrentChildAmount}`;

  return (
    <div
      className={classNames("select-team-item", {
        "background-cover": Status === "Completed"
      })}
      onClick={() => pressOnSelectGroup(Id)}
    >
      <div className="head">
        <div className="left">
          {/* time */}
          {SuTime && (
            <div className="item">
              <div className="item-icon">
                <img src={process.env.PUBLIC_URL + "/static/img/select-team-icon-1.png"} alt="" />
              </div>
              <div className="item-text">
                <b>{moment(SuTime).format("LT")}</b>
              </div>
            </div>
          )}
          {/* age */}
          {AgeCategory && (
            <div className="item">
              <div className="item-icon">
                <img src={process.env.PUBLIC_URL + "/static/img/select-team-icon-2.png"} alt="" />
              </div>
              <div className="item-text">{AgeCategory}</div>
            </div>
          )}
          {/* number */}
          {SuTrainingNumber && (
            <div className="item">
              <div className="item-icon">
                <span>№</span>
              </div>
              <div className="item-text">{SuTrainingNumber}</div>
            </div>
          )}
        </div>
        <div className="right">{mainTeamCount ? mainTeamCount : ""}</div>
      </div>
      <div className="body">
        <div className="couches">
          {SuCoachArray && SuCoachArray.length > 0 ? (
            SuCoachArray.map((couch = {}, index) => {
              const { displayValue = "", primaryImageValue = "", SuPosition = {} } = couch;
              return (
                <div className="item" key={index}>
                  <div className="item-img">
                    {primaryImageValue ? (
                      <ImageWithCookie src={primaryImageValue} alt="" />
                    ) : (
                      <img src={process.env.PUBLIC_URL + "/static/img/user.png"} alt="" />
                    )}
                  </div>
                  {SuPosition && SuPosition.displayValue && (
                    <div className="item-status">{SuPosition.displayValue}</div>
                  )}
                  <div className="item-name">{displayValue}</div>
                </div>
              );
            })
          ) : (
            <div className="couches-empty">Тренер не назначен</div>
          )}
        </div>
        <div className="info">
          <div className="members">
            <div className="item">
              <div className="first">I</div>
              <div className="last">{ICount}</div>
              <img src={process.env.PUBLIC_URL + "/static/img/select-team-icon-3.png"} alt="" />
            </div>
            <div className="item">
              <div className="first">Q</div>
              <div className="last">{QCount}</div>
              <img src={process.env.PUBLIC_URL + "/static/img/select-team-icon-3.png"} alt="" />
            </div>
            <div className="item">
              <div className="first">M</div>
              <div className="last">{MCount}</div>
              <img src={process.env.PUBLIC_URL + "/static/img/select-team-icon-3.png"} alt="" />
            </div>
          </div>
          <div className={`team ${SuGroupImage ? "" : "without-gerb"}`} ref={teamHolderRef}>
            <div className="team-wrap">
              <div className="team-gerb" ref={teamImgRef}>
                {SuGroupImage ? <ImageWithCookie src={SuGroupImage} alt="" /> : null}
              </div>
              <div className="team-name" ref={teamNameRef}>
                <span>{displayValue}</span>
              </div>
            </div>
            <button
              className="btn btn-transparent-orange"
              onClick={() => pressOnSelectGroup(Id)}
              ref={teamButtonRef}
            >
              <span>выбрать группу</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectTeamItem, (prev, next) => {
  return prev.id === next.id && prev.ICount === next.ICount && prev.QCount === next.QCount;
});
