import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Sound from "react-sound";
import { useDispatch, useSelector } from "react-redux";
import {
  standartAudio,
  participantButtonText,
  apiUrl,
  apiUploadImageUrl,
  streamStatus,
  streamButtonStatusIcon
} from "../services/constants";
import {
  updateActivityParticipantPresent,
  memberUpdateAvatarImage,
  memberUpdateRecord,
  saveParticipantVideo,
  getChampionsList,
  addUserChampion,
  getActivityParticipants,
  deleteUserChampion,
  addChampionId,
  deleteChampionId
} from "../store/actions";
import Record from "./Record";
import StreamPreview from "./StreamPreview";
import MemberVideoList from "./MemberVideoList";
import Loader from "./Loader";

import { confetti } from "../methods/confetti";
import { useReactMediaRecorder } from "../methods/react-media-recorder";
import CommentPopup from "./Comment_popup/CommentPopup";
import ChampionList from "./Champion_history/ChampionList";
import UserPopup from "./UserPopup";

export const championsTypes = {
  add: "add",
  delete: "delete"
};

const MemberPopup = ({
  memberPopOpen,
  closeMemberPop,
  memberPopId = null,
  groupId,
  isChampion,
  setIsChampion
}) => {
  const inputFile = useRef(null);
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const activeParticipant = useSelector(state => state.activityParticipantsList[memberPopId]) || {};
  const championsIds = useSelector(state => state.championIds || []);
  const [audioGreeting, setAudioGreeting] = useState(false);
  const [isSuccessVideoSend, setIsSuccessVideoSend] = useState(false);
  const {
    SuIsPresent = false,
    Contact = {},
    ActivityParticipantId,
    ActivityId
  } = activeParticipant;
  const {
    Name = "",
    audio = "",
    fileSystemImage = "",
    Id,
    debtDate,
    BirthDate,
    Attended,
    Missed,
    champion,
    championshipMonths
  } = Contact;
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioGreetingUrl, setAudioGreetingUrl] = useState(standartAudio);
  const [memberIsPresent, setMemberIsPresent] = useState(false);
  const [audioFileSubmiting, setAudioFileSubmiting] = useState(false);
  const [isProgressPopup, setIsProgressPopup] = useState(false);
  const [showStreamScreen, setShowStreamScreen] = useState(false);
  const [isBirthday, setIsBirthday] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [championType, setChampionType] = useState("");
  const [isChampionClicked, setIsChampionClicked] = useState(false);
  const [isChampionDataLoading, setIsChampionDataLoading] = useState(false);
  const [isChampionLoading, setIsChampionLoading] = useState(false);
  const [isChampionDataCorrect, setIsChampionDataCorrect] = useState(false);
  // const [isVideo, setIsVideo] = useState(false);
  const [showRecordButton, setShowRecordButton] = useState(false);
  const {
    status,
    startRecording,
    stopRecording,
    clearBlobUrl,
    mediaBlobUrl,
    previewStream
  } = useReactMediaRecorder({ video: true });
  const groupSingle = useSelector(state => state.groupSingle);
  const activityId = groupSingle["Activity"] ? groupSingle["Activity"].Id : null;
  const suTrainingNumber = groupSingle["Activity"]
    ? groupSingle["Activity"].SuTrainingNumber
    : null;

  const checkBirthday = () => {
    if (BirthDate !== undefined && memberPopOpen) {
      const date = new Date();
      const birthday = new Date(BirthDate);
      date.setHours(0, 0, 0, 0);
      date.setYear(0);
      birthday.setYear(0);

      if (date.valueOf() === birthday.valueOf()) {
        setIsBirthday(true);
      }
    } else {
      setIsBirthday(false);
    }
  };

  useEffect(() => {
    if (fileSystemImage) {
      setImagePreviewUrl(`${apiUploadImageUrl}${fileSystemImage}`);
    }
    if (audio) {
      setAudioGreetingUrl(`${apiUrl}/${audio}`);
    }
    setMemberIsPresent(SuIsPresent);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    checkBirthday();
  }, [activeParticipant]);

  useEffect(() => {
    setIsChampion(championsIds.some(playerId => playerId === Id));
  }, [isChampion]);

  useEffect(() => {
    if (isBirthday && memberPopOpen) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      confetti(canvas, context);
    }
  }, [isBirthday]);

  useEffect(() => {
    if (audio) {
      setAudioGreetingUrl(`${apiUrl}/${audio}`);
    }
  }, [audioFileSubmiting]);

  useEffect(() => {
    async function checkCameraAvailability() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setShowRecordButton(true);
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        setShowRecordButton(false);
      }
    }
    checkCameraAvailability();
  }, []);

  const stopStream = () => {
    setShowStreamScreen(false);
    clearBlobUrl();
    stopRecording();
  };

  const onClosePopup = () => {
    resetData();
    closeMemberPop();
    // setIsProgressPopup(false);
    setIsComment(false);
    setIsChampionClicked(false);
    // setIsVideo(false);
    stopStream();
  };

  const sendChoisenImg = file => {
    dispatch(memberUpdateAvatarImage(Id, file));
  };

  const uploadVideoRecording = async file => {
    let blob = await fetch(file).then(r => r.blob());
    let type = blob.type.split("/")[1];
    let video = new File([blob], "video." + type, { type: blob.type });

    setLoading(true);

    dispatch(saveParticipantVideo(Id, video)).then(success => {
      if (success.success) {
        setIsSuccessVideoSend(true);
        setLoading(false);
        clearBlobUrl();

        setTimeout(() => {
          setIsSuccessVideoSend(false);
        }, 3000);
      }
    });
  };

  const uploadAudioRecording = record => {
    setAudioFileSubmiting(true);
    dispatch(memberUpdateRecord(Id, record)).then(response => {
      setAudioFileSubmiting(false);
    });
  };

  const handlerStatusImage = status => {
    switch (status) {
      case streamStatus.stopped:
        return streamButtonStatusIcon.refresh;
      case streamStatus.idle:
        return streamButtonStatusIcon.play;
      default:
        return streamButtonStatusIcon.pause;
    }
  };

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = function() {
      sendChoisenImg(file);
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const resetData = () => {
    setImagePreviewUrl(null);
  };

  const handleUserChangeExistStatus = () => {
    if (!memberIsPresent) {
      // я пришел
      dispatch(updateActivityParticipantPresent(ActivityParticipantId, Id, true));
      setMemberIsPresent(true);
      setAudioGreeting(true);
      onClosePopup();
    } else {
      //Вышел
      dispatch(updateActivityParticipantPresent(ActivityParticipantId, Id, false));
      setMemberIsPresent(false);
      onClosePopup();
    }
  };

  /* const handleUserChangePopup = () => {
    setIsVideo(true);
  }; */

  // greeting sound
  const handleSongFinishedPlaying = () => {
    setAudioGreeting(false);
  };

  const handleSongError = () => {};

  const handleAddingComment = () => {
    setIsComment(true);
  };

  const addChampionData = async () => {
    setIsChampionDataLoading(true);

    try {
      setIsChampionClicked(false);

      if (championType === championsTypes.add) {
        setIsChampion(true);
        await dispatch(addChampionId(Id));
        await dispatch(addUserChampion({ groupId, userId: Id, activityId: ActivityId }));
      }

      if (championType === championsTypes.delete) {
        setIsChampion(false);
        await dispatch(deleteChampionId(championsIds, Id));
        await dispatch(deleteUserChampion({ groupId, userId: Id, activityId: ActivityId }));
      }

      //await dispatch(getActivityParticipants({ groupId, Id: activityId }));

      setIsChampionDataLoading(false);
    } catch (e) {
      console.log("Error: ", e);
      setIsChampionDataLoading(false);
    }
  };

  const handleAddingChampion = async () => {
    setIsChampionClicked(true);
    setChampionType(championsTypes.add);
  };

  const handleDeletingChampion = async () => {
    setIsChampionClicked(true);
    setChampionType(championsTypes.delete);
  };

  const handleChampionCancelButton = () => {
    setIsChampionClicked(false);
  };

  let $imagePreview = null;
  if (imagePreviewUrl) {
    $imagePreview = <img src={imagePreviewUrl} alt="" />;
  }

  return (
    <>
      {isBirthday && memberPopOpen ? <canvas ref={canvasRef} /> : null}
      {audioGreeting ? (
        <Sound
          url={audioGreetingUrl}
          playStatus={Sound.status.PLAYING}
          onError={handleSongError}
          onFinishedPlaying={handleSongFinishedPlaying}
        />
      ) : null}
      <Popup
        open={memberPopOpen}
        closeOnDocumentClick
        onClose={onClosePopup}
        lockScroll={true}
        nested={true}
      >
        {/* {isProgressPopup ? (
          <div className={(!showStreamScreen ? "member-detail-video-list" : "") + " member-detail"}>
            <div className="member-detail-wrap">
              <div className="row row-img">
                <div className="item item-img">
                  <div className="member-img">
                    <div className="member-img-wrap">{$imagePreview}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="title">
                    <span>{Name}</span>
                  </div>
                </div>
              </div>
              {showStreamScreen ? (
                <>
                  <div className="return-button" onClick={stopStream}>
                    <img src={process.env.PUBLIC_URL + "/static/img/return-icon.png"} />
                  </div>
                  <div className="row">
                    <div className="item-video-recorder">
                      <button
                        className="btn btn-orange item-video-events-btn member-progress-btn"
                        onClick={status !== streamStatus.recording ? startRecording : stopRecording}
                      >
                        {status === streamStatus.stopped ? (
                          <span>Перезаписать</span>
                        ) : (
                          <img
                            className="item-video-events-btn-img"
                            src={handlerStatusImage(status)}
                          />
                        )}
                      </button>
                      {loading ? (
                        <div className="item-video-recorder-loader">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <button
                            className={
                              (status === streamStatus.stopped ? "" : "disabled") +
                              " btn btn-orange member-main-btn member-video-load-btn member-progress-btn"
                            }
                            onClick={() => {
                              if (status === streamStatus.stopped) {
                                uploadVideoRecording(mediaBlobUrl);
                              }
                            }}
                          >
                            Вивантажити відео
                          </button>
                          {status === streamStatus.stopped ? (
                            <video
                              className="item-video item-video-showed"
                              src={mediaBlobUrl}
                              controls
                            />
                          ) : (
                            <StreamPreview stream={previewStream} />
                          )}
                        </>
                      )}
                      {isSuccessVideoSend ? (
                        <div className="video-success-loaded">Відео успішно завантажено</div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {showRecordButton && (
                    <div className="row row-stream-video">
                      <button
                        className="btn btn-orange member-main-btn member-progress-btn"
                        onClick={() => {
                          setShowStreamScreen(true);
                        }}
                      >
                        <span>Записати відео</span>
                      </button>
                    </div>
                  )}
                  <div className="row">
                    <MemberVideoList Id={Id} />
                  </div>
                </>
              )}
            </div>
          </div>
        )} */}
        {isComment && (
          <CommentPopup
            memberPopId={memberPopId}
            footballerId={Id}
            groupId={groupId}
            setIsComment={setIsComment}
          />
        )}

        {isChampionClicked && (
          <div className="user-confirmation-wrap">
            <UserPopup
              handleCancel={setIsChampionClicked}
              isDataLoading={isChampionDataLoading}
              setIsDataLoading={setIsChampionDataLoading}
              setIsDataCorrect={setIsChampionDataCorrect}
              loadData={addChampionData}
              championType={championType}
              setIsChampion={setIsChampion}
            />
          </div>
        )}

        {/* {isChampionDataLoading && (
          <div className="user-confirmation-wrap">
            <div className="champion-loader">
              <Loader />
            </div>
          </div>
        )} */}

        {/* {isVideo && (
          <CommentPopup
            footballerId={Id}
            setIsVideo={setIsVideo}
            setIsProgressPopup={setIsProgressPopup}
          />
        )} */}

        {!isComment && !isChampionClicked && (
          /* !isVideo && */ <>
            {isBirthday && memberPopOpen ? (
              <div className="member-detail-birthday">
                <span>Happy Birthday!</span>
              </div>
            ) : null}
            <div className="member-detail hide-addition-button">
              {debtDate !== null && debtDate !== undefined && debtDate.length > 0 ? (
                <div className="no-debt-message-wrap">
                  <span>Для отметки явки обратитесь пожалуйста к администратору</span>
                </div>
              ) : null}

              <div
                className={classNames("champion-bg", {
                  "show-champion": isChampion
                })}
                onClick={onClosePopup}
              >
                <img
                  src={process.env.PUBLIC_URL + "/static/img/champion-bg.png"}
                  alt="champion-bacground"
                />
              </div>

              <div className="member-detail-wrap">
                <div className="row row-img">
                  <div className="item item-img">
                    <div className="member-img">
                      <div className="member-img-wrap">{$imagePreview}</div>
                    </div>
                  </div>
                </div>
                <div onClick={handleAddingComment} className="comment">
                  <img src={process.env.PUBLIC_URL + "/static/img/add-comment-icon.svg"} alt="" />
                </div>
                {suTrainingNumber &&
                  (suTrainingNumber.includes("A") || suTrainingNumber.includes("А")) && (
                    <>
                      <div
                        onClick={handleAddingChampion}
                        className={classNames("champion", {
                          "show-champion": !isChampion
                        })}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/static/img/champion-icon.svg"}
                          alt=""
                        />
                      </div>
                      <div
                        onClick={handleDeletingChampion}
                        className={classNames("champion delete-champion", {
                          "show-champion": isChampion
                        })}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/static/img/purple-champion-icon.svg"}
                          alt=""
                        />
                      </div>
                    </>
                  )}
                <div className="row">
                  <div className="item item-title">
                    <div className="title">
                      <span>{Name}</span>
                    </div>
                    <div
                      className={classNames("star", {
                        "show-champion": isChampion
                      })}
                    >
                      <img src={process.env.PUBLIC_URL + "/static/img/star-icon.svg"} alt="star" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="item item-img-file">
                    <label htmlFor="fileLoad" className="item-img-file-text">
                      Добавить фото
                    </label>
                    <input
                      ref={inputFile}
                      className="item-img-file-input"
                      type="file"
                      id="fileLoad"
                      accept="image/*"
                      capture="user"
                      onChange={e => handleImageChange(e)}
                    />
                  </div>
                </div>
                <div className="row addition-button">
                  <div className="item item-btn">
                    <Record
                      memberPopOpen={memberPopOpen}
                      uploadAudioRecording={uploadAudioRecording}
                      audioFileSubmiting={audioFileSubmiting}
                    />
                  </div>
                  <div className="item item-btn">
                    <button
                      className="btn btn-transparent-orange"
                      onClick={() => inputFile.current.click()}
                    >
                      <span>
                        <img src={process.env.PUBLIC_URL + "/static/img/popup-icon-2.png"} alt="" />
                        Добавить фото
                      </span>
                    </button>
                  </div>
                </div>
                <div className="row row-btns">
                  {/* <button
                    className="btn btn-orange member-main-btn member-progress-btn"
                    onClick={() => {
                      handleUserChangePopup();
                    }}
                  >
                    <span>Прогрес дитини</span>
                  </button> */}
                  <button
                    disabled={audioFileSubmiting}
                    className={
                      (debtDate !== null && debtDate !== undefined && debtDate.length > 0
                        ? "disabled-btn"
                        : "") + " btn member-main-btn btn-orange"
                    }
                    onClick={() => handleUserChangeExistStatus()}
                  >
                    <span>
                      {memberIsPresent ? participantButtonText.Iout : participantButtonText.Icame}
                    </span>
                  </button>
                </div>
                <div className="row row-wrapper">
                  <div className="row counters">
                    <div className="counter attended">
                      <span>Пройдены тренировки</span>
                      <span className="attended-number">{`${Attended ? Attended : "0"},00`}</span>
                    </div>
                    {/* <div className="counter missed">
                      <span>Пропустив</span>
                      <span className="missed-number">{`${Missed ? Missed : "0"},00`}</span>
                    </div> */}
                  </div>
                  {championshipMonths && championshipMonths.length > 0 && !champion && (
                    <div className="row champion-history">
                      <span>Ты был чемпионом:</span>
                      <ChampionList list={championshipMonths} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    </>
  );
};

export default MemberPopup;
