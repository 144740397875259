import React from "react";

const CommentItem = ({ commentCrm }) => {
  const {
    comment,
    category,
    commentator,
    date,
  } = commentCrm;

  return (
    <div className="comment-crm-wrap">
      <div className="comment-header">
        <div className="comment-info">
          <div className="comment-author">
            <div className="comment-avatar">
              <img src={process.env.PUBLIC_URL + "/static/img/avatar-icon.svg"} alt="" />
            </div>
            <div className="comment-title comment-font">
              <span>{commentator}</span>
            </div>
          </div>
          <div className="comment-date comment-font">
            <span>{date}</span>
          </div>
        </div>
        <div className="comment-category">
          <span>Категория: {category}</span>
        </div>
      </div>
      <div className="comment-crm">
        <span>{comment}</span>
      </div>
    </div>
  );
};

export default CommentItem;
