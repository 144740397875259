import React, { useState, useEffect, useRef } from 'react';

import Recorder from 'recorder-js';
import axios from 'axios';

// const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const AudioContext = window.AudioContext || window.webkitAudioContext;
var recordingTemp = null;

const Record = ({ memberPopOpen, uploadAudioRecording, audioFileSubmiting }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [normalButtonView, setNormalButtonView] = useState(true);

  var recorder = useRef(null);
  var streamHolder = useRef(null);
  var recordTimer = useRef(null);

  useEffect(() => {
    return () => {
      // unmount
      resetState();
    };
  }, [memberPopOpen]);

  useEffect(() => {
    if(audioFileSubmiting) resetState();
  }, [audioFileSubmiting]);

  function startRecording() {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          streamHolder.current = stream;

          recorder.current = new Recorder(new AudioContext())

          recorder.current.init(stream);
          recorder.current.start().then(() => {
            //adter 5s - stop recording
            recordTimer.current = setTimeout(() => {
              stopRecording();
            }, 5000);

            setIsRecording(true);
          });
        })
        .catch(err => {
          setNormalButtonView(true);
          alert('Ошибка отсутствует доступ к микрофону');
          console.warn('Uh oh... unable to get stream...', err);
        });
    } catch (error) {
      setNormalButtonView(true);
      alert('Ошибка отсутствует доступ к микрофону');
      console.warn('Uh oh... unable to get stream...', error);
    }
  }

  function stopRecording() {
    recorder.current.stop().then(({ blob: blobFromRecording, buffer }) => {
      recordingTemp = blobFromRecording;
      if (streamHolder.current) {
        streamHolder.current.getAudioTracks()[0].stop();
      }

      setIsRecording(false);
      // buffer is an AudioBuffer
    });
  }

  function endRecordTimer() {
    clearTimeout(recordTimer.current);
  }

  function download() {
    // TODO remove after good api
    //saveFile(recordingTemp, 'my-audio-file.wav'); // downloads a .wav file
    uploadAudioRecording(recordingTemp)
  }

  function saveFile(blob, filename) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  function resetState() {
    if (streamHolder.current) {
      streamHolder.current.getAudioTracks()[0].stop();
    }
    recorder = null;
    recordingTemp = null;

    endRecordTimer();
    setNormalButtonView(true);
  }

  const onPressRecord = () => {
    if (isRecording) {
      stopRecording();
    } else {
      setNormalButtonView(false);
      startRecording();
    }
  };

  const onPressSave = () => {
    if (isRecording) {
      endRecordTimer();
      stopRecording();
    } else {
      download();
    }
  };

  const onPressReload = () => {
    console.warn('onPressReload');
    //reset prev recording
    recordingTemp = null;
    startRecording();
  };

  return (
    <div className={`record-holder ${audioFileSubmiting ? 'disabled' : ''}`}>
      {normalButtonView ? (
        <button className="btn btn-transparent-orange" onClick={onPressRecord}>
          <span>
            <img src={process.env.PUBLIC_URL + '/static/img/popup-icon-1.png'} alt="" />
            {audioFileSubmiting ? 'Загрузка...' : 'приветствие'}
          </span>
        </button>
      ) : (
        <div className="record-holder-mini">
          <button
            className="btn btn-transparent-orange"
            disabled={isRecording && !recordingTemp}
            onClick={onPressReload}
          >
            <span>
              <img src={process.env.PUBLIC_URL + '/static/img/reload-icon.png'} alt="" />
            </span>
          </button>
          <button className="btn btn-transparent-orange" onClick={onPressSave}>
            <span>
              {isRecording ? (
                <img src={process.env.PUBLIC_URL + '/static/img/stop-icon.png'} alt="" />
              ) : (
                <img src={process.env.PUBLIC_URL + '/static/img/save-icon.png'} alt="" />
              )}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Record;
