import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { apiUploadImageUrl } from "../../services/constants";
import { addChampionId } from "../../store/actions";

const GameMember = ({ data, openMemberPop }) => {
  const { Contact, Id } = data;
  const {
    Name = "",
    Istatus = false,
    Qstatus = false,
    Mstatus = false,
    fileSystemImage,
    champion
  } = Contact;
  let additionClasses = "";
  const championsIds = useSelector(state => state.championIds);
  const dispatch = useDispatch();
  const [isChampionId, setIsChampionId] = useState(false);
  if (Istatus) additionClasses = additionClasses += " success";
  if (Qstatus) additionClasses = additionClasses += " warn";
  if (Mstatus) additionClasses = additionClasses += " pink";
  const memberAvatar = fileSystemImage ? `${apiUploadImageUrl}${fileSystemImage}` : null;
  const clearName = Name.replace("not valid", "")
    .replace("Not valid", "")
    .replace("valid", "")
    .replace("transition", "");

  useEffect(() => {
    if (championsIds) {
      setIsChampionId(championsIds.some(playerId => playerId === Contact.Id));
    }
  }, [championsIds]);

  useEffect(() => {
    if (champion) {
      dispatch(addChampionId(Contact.Id));
    }
  }, []);

  return (
    <div
      className={classNames(`game-field-member ${additionClasses}`, {
        "game-field-member-champion": isChampionId
      })}
      onClick={() => openMemberPop(Id)}
    >
      <div className="member-img">
        <div className="member-img-wrap">
          {memberAvatar ? <img src={memberAvatar} alt="" /> : null}
        </div>
      </div>
      <div
        className={classNames("member-cup", {
          "show-champion": isChampionId
        })}
      >
        <img src={process.env.PUBLIC_URL + "/static/img/cup.png"} alt="" />
      </div>
      <div className="member-info">
        <div className="member-name">
          <span>{clearName}</span>
        </div>
        <div
          className={classNames("member-champion", {
            "show-champion": isChampionId
          })}
        >
          <div className="member-star">
            <img src={process.env.PUBLIC_URL + "/static/img/mini-star-icon.svg"} alt="" />
          </div>
          <span>Чемпион месяца</span>
        </div>
      </div>
    </div>
  );
};

export default GameMember;
