import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationLogin } from '../store/actions.js';

const Login = ({ history: routerHistory }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const loginError = useSelector(state => state.loginError);
  const url = new URL(window.location.href);
  const username = url.searchParams.get("username");
  const pass = url.searchParams.get("password");
  const params = new URLSearchParams(url.search);

  const formOnSubmit = (values, actions) => {
    setIsLoading(true);
    setIsError(false)
    const { login, password } = values;
    dispatch(authenticationLogin({ login, password }))
      .then(success => {
        if (success) {
          routerHistory.push('/select-team');
        } else {
          setIsLoading(false);
          setIsError(true)
        }
      })
      .catch(e => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const formValidate = values => {
    let errors = {};
    if (!values.login) {
      errors.login = `Поле обязательно`;
    }
    if (!values.password) {
      errors.password = `Поле обязательно`;
    }
    return errors;
  };

  if(pass !== null && username !== null){
    try {
      const login = atob(username);
      const password = atob(pass);

      dispatch(authenticationLogin({ login, password }))
        .then(success => {
          if (success) {
            routerHistory.push('/select-team');
          } else {

          }
        })
        .catch(e => {

      });
      params.delete('username');
      params.delete('password');
    } catch (error) {
        console.log(error)
    }

  }

  useEffect(() => {
    if (isError && loginError){
      alert(loginError);
    }
  }, [isError]);

  return (
    <div className="login-page" data-testid="login-page">
      <div className="login-page-bg">
        <img src={process.env.PUBLIC_URL + '/static/img/login-bg.jpg'} alt="" />
      </div>
      <div className="container">
        <div className="login-page-logo" data-testid="login-logo">
          <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" />
        </div>

        <div className="login-page-form">
          <Formik
            className="login-page-form"
            initialValues={{
              login: '',//gudelovaO
              password: ''//olgaG
            }}
            validate={formValidate}
            onSubmit={formOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Field name="login" className="custom-input" placeholder="ЛОГИН" />
                {errors.login && touched.login ? (
                  <div className="custom-input-error">{errors.login}</div>
                ) : null}
                <Field name="password" type="password" className="custom-input" placeholder="ПАРОЛЬ" data-testid="login-input"/>
                {errors.password && touched.password ? (
                  <div className="custom-input-error">{errors.password}</div>
                ) : null}

                <button type="submit" disabled={isLoading} className="btn btn-orange">
                  <span>ВОЙТИ</span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
