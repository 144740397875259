import React from "react";

const ChampionItem = ({ item }) => {
  const { month, year } = item;
  const getMonthName = (month) => {
    const months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];

    if (month >= 1 && month <= 12) {
      return `${months[month - 1]}, `;
    } else {
      return '';
    }
  };

  const monthName = getMonthName(month);

  return (
    <span className="champion-month">{monthName}{year}</span>
  );
};

export default ChampionItem;
