import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../services/constants.js";
import { changeTrainingStatus, setTrainingStatus } from "../store/actions.js";

import UserPopup from "./UserPopup.jsx";
import VideoListPopup from "./Video_popup/VideoListPopup.jsx";
import VideoPlayerPopup from "./Video_popup/VideoPlayerPopup.jsx";

const trainingButtons = {
  open: "open",
  close: "close"
};

const trainingButtonsText = {
  Completed: "Открыть тренировку",
  Noncompleted: "Закрыть тренировку"
};

const TrainingPopup = ({ setIsTrainingClicked, fileTraining, videoTraining }) => {
  const dispatch = useDispatch();
  const trainingStatus = useSelector(state => state.trainingStatus);
  const groupSingle = useSelector(state => state.groupSingle);
  const activityId = groupSingle["Activity"] ? groupSingle["Activity"].Id : null;
  const activityStatus = groupSingle["Activity"] ? groupSingle["Activity"].Status : null;
  const checkStatus =
    trainingStatus === trainingButtons.close ? trainingButtons.open : trainingButtons.close;
  const checkStatusByActivity =
    activityStatus === "Completed" ? trainingButtons.open : trainingButtons.close;

  const [isEnglishClicked, setIsEnglishClicked] = useState(false);
  const [isTrainingDataLoading, setIsTrainingDataLoading] = useState(false);
  const [isTrainingDataCorrect, setIsTrainingDataCorrect] = useState(false);
  const [isVideoClicked, setIsVideoClicked] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [status, setStatus] = useState(trainingStatus ? checkStatus : checkStatusByActivity);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const isVideoDisabled = videoTraining.length < 1;
  const isFileDisabled = fileTraining === false;
  const fileUrl = `${apiUrl}/${fileTraining ? fileTraining : ""}#toolbar=0`;

  const handleClickCancelButton = () => {
    if (setIsTrainingClicked) {
      setIsTrainingClicked(false);
    }
  };

  const handleEnglishClick = () => {
    setIsEnglishClicked(true);
  };

  const handleVideoPlayerOpen = link => {
    setIsVideoClicked(true);
    setIsEnglishClicked(false);
    setVideoLink(link);
  };

  const handleVideoPlayerClose = () => {
    setIsVideoClicked(false);
    setIsEnglishClicked(true);
  };

  const handleTrainingClick = async () => {
    setIsStatusLoading(true);

    try {
      await dispatch(changeTrainingStatus({ status: status, activityId: activityId }));

      setStatus(prevStatus =>
        prevStatus === trainingButtons.close ? trainingButtons.open : trainingButtons.close
      );
      setIsStatusLoading(false);
    } catch (e) {
      console.log("Error training status: ", e);
      setIsStatusLoading(false);
    }
  };

  return (
    <div
      className={classNames("user-confirmation-wrap", {
        "video-list-wrap": isEnglishClicked,
        "video-player-wrap": isVideoClicked
      })}
    >
      {isTrainingDataCorrect ? (
        <>
          {!isEnglishClicked && isVideoClicked && (
            <VideoPlayerPopup
              videoSrc={videoLink}
              handleVideoPlayerClose={handleVideoPlayerClose}
            />
          )}

          {isEnglishClicked && !isVideoClicked && (
            <VideoListPopup
              setIsEnglishClicked={setIsEnglishClicked}
              isVideoClicked={isVideoClicked}
              setIsVideoClicked={setIsVideoClicked}
              handleVideoPlayerOpen={handleVideoPlayerOpen}
              handleVideoPlayerClose={handleVideoPlayerClose}
              videoTraining={videoTraining}
            />
          )}

          {!isEnglishClicked && !isVideoClicked && (
            <>
              <div className="back-btn" onClick={handleClickCancelButton}>
                <img src={process.env.PUBLIC_URL + "/static/img/left-arrow-icon.png"} />
                <span>Вернуться</span>
              </div>

              <div className="content-popup">
                <div className="training-buttons-wrap">
                  <a
                    href={fileUrl}
                    className={classNames("btn btn-orange cancel-btn training-btn-transparent", {
                      "training-btn-disabled": isFileDisabled
                    })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Конспект тренировки</span>
                  </a>
                  <button
                    className={classNames("btn btn-orange training-btn", {
                      "video-btn-disabled": isVideoDisabled
                    })}
                    onClick={handleEnglishClick}
                  >
                    <span>English</span>
                  </button>
                </div>{" "}
                <button className="btn btn-none-border" onClick={handleTrainingClick}>
                  <span>
                    {isStatusLoading
                      ? "Загрузка..."
                      : status === trainingButtons.close
                        ? trainingButtonsText.Noncompleted
                        : trainingButtonsText.Completed
                    }
                  </span>
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <UserPopup
          handleCancel={setIsTrainingClicked}
          isDataLoading={isTrainingDataLoading}
          setIsDataLoading={setIsTrainingDataLoading}
          setIsDataCorrect={setIsTrainingDataCorrect}
        />
      )}
    </div>
  );
};

export default TrainingPopup;
